import { BrowserModule, Title } from '@angular/platform-browser';
import { forwardRef, Injectable, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {
  RouterModule,
  RouterStateSnapshot,
  TitleStrategy,
} from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import {
  APP_CONFIG_STAGES,
  AppConfigService,
} from '@tremaze/shared/util-app-config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BaseInterceptor } from '@tremaze/shared/core/base-http-interceptor';
import packageInfo from '../../../../package.json';

@Injectable({ providedIn: 'root' })
class HubAppConfigService implements AppConfigService {
  basePath = environment.basePath;
  clientId: string;
  clientSecret: string;
  logoPaths: { light: string; dark: string };

  tokenPath: string;

  get version() {
    return packageInfo.version;
  }

  state: APP_CONFIG_STAGES = environment.production ? 'PROD' : 'DEV';

  get isProd(): boolean {
    return this.state === 'PROD';
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () =>
            import('@tremaze/hub/main').then((m) => m.HubMainModule),
        },
      ],
      { initialNavigation: 'enabledBlocking' }
    ),
  ],
  providers: [
    JsonSerializer,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    { provide: HTTP_INTERCEPTORS, useExisting: BaseInterceptor, multi: true },
    { provide: AppConfigService, useClass: HubAppConfigService },
    { provide: TitleStrategy, useClass: forwardRef(() => HubTitleStrategy) },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

@Injectable()
export class HubTitleStrategy extends TitleStrategy {
  constructor(private title: Title) {
    super();
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if(!title?.length) {
      this.title.setTitle('Tremaze HUB');
      return;
    }
    this.title.setTitle(title + ' - Tremaze HUB');
  }
}
