import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { first, isObservable, Observable, of, switchMap } from 'rxjs';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { normalizeUrl } from '@tremaze/shared/util-http';
import { doOnError } from '@tremaze/shared/util/rxjs';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({ providedIn: 'root' })
export class BaseInterceptor implements HttpInterceptor {
  get basePathOverride(): string {
    return this._basePathOverride;
  }

  set basePathOverride(value: string) {
    this._basePathOverride = value;
  }

  private _basePathOverride?: string;

  resetBasePathOverride() {
    this._basePathOverride = undefined;
  }

  constructor(private appConfigService: AppConfigService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const basePath$ = this._basePathOverride
      ? of(this._basePathOverride)
      : isObservable(this.appConfigService.basePath)
      ? this.appConfigService.basePath
      : of(this.appConfigService.basePath);
    return basePath$.pipe(
      first(),
      switchMap((basePath) => {
        if (!request.url.startsWith('http')) {
          basePath = normalizeUrl(basePath);
          let url = request.url;
          if (!url.startsWith('/')) {
            url = `/${url}`;
          }
          const preferredLang = navigator.language;
          request = request.clone({
            url: normalizeUrl(`${basePath}${url}`),
            headers: request.headers.set('X-Forwarded-Proto', 'https'),
            params: request.params.set('lang', preferredLang),
          });
        }
        return next.handle(request).pipe(
          doOnError((e) => {
            Sentry.captureException(e, {
              extra: {
                request,
              },
            });
          })
        );
      })
    );
  }
}
